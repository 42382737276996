import {
  Box,
  Button,
  IconButton,
  Popover,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  PaletteMode
} from '@mui/material'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import React, { useEffect, useRef, useState, useCallback, FC } from 'react'
import Settings03Icon from '@untitled-ui/icons-react/build/esm/Settings03'
import { useAuth } from '../../../hooks/use-auth'
import { useSettings } from '../../../hooks/use-settings'
import { Settings } from '../../../types/settings'
import { Dr } from '../../../types/dr'
import { thunks } from 'src/thunks/dr'
import { useDispatch, useSelector } from 'src/store'
import AccessMenu from 'src/guards/access-menu'
import { FunctionsRdoEnum } from 'src/enums/functions-rdo.enum'

interface Option {
  label: string
  value: PaletteMode
}

const themeOptions: Option[] = [
  {
    label: 'Lumineux',
    value: 'light'
  },
  {
    label: 'Sombre',
    value: 'dark'
  }
]

const SettingsButton: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const { user, redirectAuth } = useAuth()
  const { dr } = useSelector((state) => state)
  const { paletteMode, handleUpdate } = useSettings()
  const [isOpen, setOpen] = useState(false)
  const [listDr, setListDr] = useState<Dr[]>([])
  const [drIdSelected, setDrIdSelected] = useState<string>()
  const [paletteModeSelected, setPaletteModeSelected] = useState<PaletteMode>()
  const dispatch = useDispatch()
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = useCallback(
    (field: keyof Settings, value: unknown): void => {
      handleUpdate?.({
        [field]: value
      })
    },
    [handleUpdate]
  )

  const saveDr = async () => {
    try {
      await axios.put('/api/account/me', { dr_id: drIdSelected })
    } catch (err: any) {
      if (err.response.status === 401) {
        return redirectAuth()
      }
    }
  }

  const handleSave = () => {
    handleChange('paletteMode', paletteModeSelected)
    if (paletteModeSelected) {
      // Set the selected theme in localStorage
      localStorage.setItem('theme', paletteModeSelected)
      // Update the data-theme attribute
      document.documentElement.setAttribute('data-theme', paletteModeSelected)
    }
    if (dr.id !== drIdSelected) {
      saveDr()
        .then(() => {
          const dr = listDr.find((dr) => drIdSelected === dr.id)
          if (dr) {
            dispatch(thunks.changeCurrentDr(dr))
            toast.success('Mise à jour de votre direction régionale')
          }
        })
        .catch((err) => {
          console.error(err)
          toast.error(err)
        })
    }
    setOpen(false)
  }

  useEffect(() => {
    const fetchDR = async () => {
      const res = await axios.get<Dr[]>('/api/dr')
      if (res.status === 204) {
        setListDr([])
      } else {
        const drSorted = [...res.data].sort((drA, drB) => drA.name.localeCompare(drB.name))
        setListDr(drSorted)
        const dr = drSorted.find((dr) => user?.drId === dr.id)
        if (dr) {
          dispatch(thunks.changeCurrentDr(dr))
          setDrIdSelected(dr.id)
        }
      }
    }
    fetchDR()
  }, [dispatch, user])

  useEffect(() => {
    setPaletteModeSelected(paletteMode)
  }, [paletteMode])

  return (
    <>
      <Tooltip title="Paramètres">
        <IconButton
          color="inherit"
          onClick={handleOpen}
          ref={anchorRef}>
          <SvgIcon fontSize="small">
            <Settings03Icon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        disableScrollLock
        onClose={handleClose}
        open={isOpen}
        PaperProps={{ sx: { width: 280 } }}>
        <Box sx={{ p: 1 }}>
          <Typography
            color="inherit"
            variant="h6">
            Paramètres
          </Typography>
          <AccessMenu functionRdo={FunctionsRdoEnum.DR}>
            <Box sx={{ p: 1, mt: 1 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Direction Régionale"
                name="dr"
                onChange={(event) => setDrIdSelected(event.target.value)}
                select
                SelectProps={{ native: true }}
                value={drIdSelected}>
                {listDr.map((dr) => (
                  <option
                    key={dr.id}
                    value={dr.id}>
                    {dr.name}
                  </option>
                ))}
              </TextField>
            </Box>
          </AccessMenu>
          <Box sx={{ p: 1, mt: 1 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Thème"
              name="theme"
              onChange={(event) => setPaletteModeSelected(event.target.value as PaletteMode)}
              select
              SelectProps={{ native: true }}
              value={paletteModeSelected}>
              {themeOptions.map((themeOption) => (
                <option
                  key={themeOption.value}
                  value={themeOption.value}>
                  {themeOption.label}
                </option>
              ))}
            </TextField>
          </Box>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSave}>
              Sauvegarder
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  )
}

export default SettingsButton
