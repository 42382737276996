import ReactDOM from 'react-dom/client'
import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { App } from './app'
// Sert a utiliser fullScreenControl dans toute l'appli sur les <MapContainer>
import 'leaflet.fullscreen/Control.FullScreen'
import 'leaflet.fullscreen/Control.FullScreen.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
)
