import type { FC } from 'react'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Drawer, Stack, Divider, Typography, Button, SvgIcon } from '@mui/material'
import File04Icon from '@untitled-ui/icons-react/build/esm/File04'
import { useTheme } from '@mui/material/styles'
import { Logo } from 'src/components/logo'
import { RouterLink } from 'src/components/router-link'
import { Scrollbar } from 'src/components/scrollbar'
import { usePathname } from 'src/hooks/use-pathname'
import { paths } from 'src/paths'
import type { NavColor } from 'src/types/settings'
import type { Section } from '../config'
import Avatar from '@mui/material/Avatar'
import { MobileNavSection } from './mobile-nav-section'
import { useAuth } from 'src/hooks/use-auth'
import getStatus from 'src/utils/getStatus'

const MOBILE_NAV_WIDTH: number = 280

const useCssVars = (color: NavColor): Record<string, string> => {
  const theme = useTheme()

  return useMemo((): Record<string, string> => {
    switch (color) {
      // Blend-in and discreet have no difference on mobile because
      // there's a backdrop and differences are not visible
      case 'blend-in':
      case 'discreet':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.neutral[100],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400]
          }
        } else {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.text.primary,
            '--nav-logo-border': theme.palette.neutral[100],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.text.secondary,
            '--nav-item-hover-bg': theme.palette.action.hover,
            '--nav-item-active-bg': theme.palette.action.selected,
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[400],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
            '--nav-item-chevron-color': theme.palette.neutral[400],
            '--nav-scrollbar-color': theme.palette.neutral[900]
          }
        }

      case 'evident':
        return {
          '--nav-bg': theme.palette.neutral[800],
          '--nav-color': theme.palette.common.white,
          '--nav-logo-border': theme.palette.neutral[700],
          '--nav-section-title-color': theme.palette.neutral[400],
          '--nav-item-color': theme.palette.neutral[400],
          '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
          '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
          '--nav-item-active-color': theme.palette.common.white,
          '--nav-item-disabled-color': theme.palette.neutral[500],
          '--nav-item-icon-color': theme.palette.neutral[400],
          '--nav-item-icon-active-color': theme.palette.primary.main,
          '--nav-item-icon-disabled-color': theme.palette.neutral[500],
          '--nav-item-chevron-color': theme.palette.neutral[600],
          '--nav-scrollbar-color': theme.palette.neutral[400]
        }

      default:
        return {}
    }
  }, [theme, color])
}

interface MobileNavProps {
  color?: NavColor
  onClose?: () => void
  open?: boolean
  sections?: Section[]
}

export const MobileNav: FC<MobileNavProps> = (props) => {
  const { color = 'evident', open, onClose, sections = [] } = props
  const pathname = usePathname()
  const cssVars = useCssVars(color)
  const { user } = useAuth()

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          color: 'var(--nav-color)',
          width: MOBILE_NAV_WIDTH
        }
      }}
      variant="temporary">
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)'
          }
        }}>
        <Stack sx={{ height: '100%' }}>
          <Stack
            alignItems="center"
            direction="row"
            sx={{ p: 1 }}>
            <Box
              component={RouterLink}
              href={paths.index}>
              <Logo />
            </Box>
          </Stack>
          <Divider />
          {/* User informations */}
          <Stack>
            <Box
              component={RouterLink}
              href={paths.account}
              sx={{ textDecoration: 'none', p: 2 }}>
              <Box
                display="flex"
                justifyContent="center">
                <Avatar
                  sx={{
                    height: 64,
                    width: 64
                  }}
                  alt={user?.cn}
                />
              </Box>
              <Box
                mt={2}
                textAlign="center">
                <Typography
                  variant="h6"
                  color={(theme) => theme.palette.common.white}
                  display="inline">
                  {user?.cn}
                </Typography>
                <Typography
                  variant="body2"
                  color={(theme) => theme.palette.neutral[400]}>
                  Votre statut:
                  {' ' +
                    getStatus(user?.roles?.map((role) => role.gardianSesameProfil))
                      .split('_')
                      .pop()}
                </Typography>
              </Box>
            </Box>
          </Stack>
          {/* END User informations */}
          <Divider />
          <Stack
            component="nav"
            spacing={2}
            sx={{
              flexGrow: 1,
              px: 2,
              mt: 2
            }}>
            {sections.map((section, index) => (
              <MobileNavSection
                items={section.items}
                key={index}
                pathname={pathname}
                subheader={section.subheader}
              />
            ))}
          </Stack>
          <Box sx={{ p: 3 }}>
            <Typography variant="subtitle1">Besoin d'aide ?</Typography>
            <Typography
              color="neutral.400"
              sx={{ mb: 2 }}
              variant="body2">
              Consulter la documentation.
            </Typography>
            <Button
              component={RouterLink}
              fullWidth
              href={paths.documentation}
              startIcon={
                <SvgIcon>
                  <File04Icon />
                </SvgIcon>
              }
              variant="contained">
              Documentation
            </Button>
          </Box>
        </Stack>
      </Scrollbar>
    </Drawer>
  )
}

MobileNav.propTypes = {
  color: PropTypes.oneOf<NavColor>(['blend-in', 'discreet', 'evident']),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sections: PropTypes.array
}
