const getStatus = (profiles: string[] = []) => {
  const profiles_63g = profiles.filter((profile) => profile.includes('63G'))
  if (profiles_63g.length) {
    if (profiles.find((profile) => profile.includes('ADMIN'))) {
      return '63G_ADMIN'
    }
    if (profiles.find((profile) => profile.includes('EXPLOITANT-RESPONSABLE-SECURITE'))) {
      return '63G_EXPLOITANT RESPONSABLE SECURITE'
    }
    if (profiles.find((profile) => profile.includes('MANAGER'))) {
      return '63G_MANAGER'
    }
    if (profiles.find((profile) => profile.includes('PILOTE'))) {
      return '63G_PILOTE'
    }
    return 'VISITEUR'
  } else {
    return 'VISITEUR'
  }
}

export default getStatus
