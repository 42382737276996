import type { FC } from 'react'
import { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import User03Icon from '@untitled-ui/icons-react/build/esm/User03'
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SvgIcon,
  Typography
} from '@mui/material'
import { useAuth } from '../../../hooks/use-auth'
import { paths } from 'src/paths'

interface AccountPopoverProps {
  anchorEl: null | Element
  onClose?: () => void
  open?: boolean
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props
  const { logout } = useAuth()
  const { user } = useAuth()
  const navigate = useNavigate()

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      onClose?.()
      logout()
      navigate(paths.logout)
    } catch (err) {
      console.error(err)
      toast.error('Erreur lors de la déconnexion.')
    }
  }, [logout, onClose, navigate])

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}>
      <Box sx={{ p: 1 }}>
        <ListItemButton
          component={Link}
          to="/account"
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5
          }}>
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <User03Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Mon compte</Typography>} />
        </ListItemButton>
      </Box>
      <Divider sx={{ my: '0 !important' }} />
      <Box
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'center'
        }}>
        <Button
          color="inherit"
          onClick={handleLogout}
          size="small">
          Déconnexion
        </Button>
      </Box>
    </Popover>
  )
}

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
}
