import { useRef, useState } from 'react'
import { Avatar, Box, ButtonBase, Hidden, Typography, useTheme } from '@mui/material'
import { useAuth } from '../../../hooks/use-auth'
import { AccountPopover } from './account-popover'

const AccountButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const { user } = useAuth()
  const theme = useTheme()

  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        ref={anchorRef}
        component={ButtonBase}
        onClick={handleOpen}>
        <Avatar
          sx={{
            height: 32,
            width: 32,
            marginRight: theme.spacing(1)
          }}
          alt="User"
        />
        <Hidden smDown>
          <Typography
            variant="subtitle2"
            color="inherit">
            {user?.cn}
          </Typography>
        </Hidden>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClose}
        open={isOpen}
      />
    </>
  )
}

export default AccountButton
