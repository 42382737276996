import type { FC, ReactNode } from 'react'
import React, { createContext, useEffect, useReducer } from 'react'
import { User } from '../../types/user'

const issuerLocal = 'GardianWebSSO'

type AppState = {
  returnTo?: string
}
type StateUser = {
  isAuthenticated: boolean
  isInitialized: boolean
  user: User | null
}
const initialAuthState: StateUser = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user
      }
    }
    case 'LOGIN': {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user
      }
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      }
    }
    default: {
      return { ...state }
    }
  }
}
export interface AuthContextType extends StateUser {
  issuer: string
  redirectAuth: (appState?: AppState) => Promise<void>
  logout: () => Promise<void>
}

export const AuthContext = createContext({
  ...initialAuthState,
  issuer: issuerLocal,
  logout: () => Promise.resolve(),
  redirectAuth: () => Promise.resolve()
})
interface AuthProviderProps {
  children: ReactNode
}
export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState)

  const logout = () => {
    dispatch({ type: 'LOGOUT' })
  }

  const redirectAuth = () => {
    const searchParams = new URLSearchParams()
    if (window.location.pathname && window.location.pathname !== '/')
      searchParams.append('resource', window.location.pathname + window.location.search)
    const url = new URL(window.location.origin + '/api/auth/login')
    url.search = searchParams.toString()

    window.location.assign(url.toString())
    return null
  }

  useEffect(() => {
    const initialise = async () => {
      try {
        const response = await fetch('/api/account/me', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })

        if (response.status === 200) {
          const userReceived: User = await response.json()
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: Object.assign(new User(), userReceived)
            }
          })
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          })
        }
      } catch (err) {
        console.error('Auth error : ', err)
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      }
    }

    initialise()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'GardianWebSSO',
        logout,
        redirectAuth
      }}>
      {children}
    </AuthContext.Provider>
  )
}
export const AuthConsumer = AuthContext.Consumer
