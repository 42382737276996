import { Dayjs } from 'dayjs'
import { ProfilesEnum } from '../enums/profiles.enum'
import { Dr } from './dr'
import { Role } from './role'
import { RoleFunction } from './role-function'

export class UserPrincipal {
  roles: Role[]

  constructor() {
    this.roles = []
  }

  public get isManager(): boolean {
    const role = this.roles?.find((role) => role.gardianSesameProfil === ProfilesEnum.MANAGER)
    return Boolean(role)
  }
  public get isPilot(): boolean {
    const role = this.roles?.find((role) => role.gardianSesameProfil === ProfilesEnum.PILOTE)
    return Boolean(role)
  }

  public get isERS(): boolean {
    const role = this.roles?.find(
      (role) => role.gardianSesameProfil === ProfilesEnum.EXPLOITANT_RESPONSABLE_SECURITE
    )
    return Boolean(role)
  }
  public get isAdmin(): boolean {
    const role = this.roles?.find((role) => role.gardianSesameProfil === ProfilesEnum.ADMIN)
    return Boolean(role)
  }

  public get isSimpleUser(): boolean {
    const role = this.roles?.find((role) => role.gardianSesameProfil === ProfilesEnum.UTILISATEUR)
    return Boolean(role)
  }

  public get isVisitor(): boolean {
      const role = this.roles?.find((role) => role.gardianSesameProfil === ProfilesEnum.VISITEUR)
      return Boolean(role)
  }

    // Utilisateur invité (VISITEUR) ou accès limité (63G_UTILISATEUR)
  public get isSimpleOrVisitorUser(): boolean {
      return this.isSimpleUser || this.isVisitor
  }

    // Utilisateur avancé (63G_ADMIN, 63G_ERS, 63G_MANAGER, 63G_PILOTE)
  public get isAdvancedUser(): boolean {
      return this.isAdmin || this.isERS || this.isManager || this.isPilot
  }

    // Utilisateur à accès limité uniquement
  public get isSimpleUserAndNotAdvancedUser(): boolean {
      return this.isSimpleOrVisitorUser && !this.isAdvancedUser
  }
}

export class User extends UserPrincipal {
  cn: string | undefined
  createdAt: string | undefined
  drId: string | undefined
  dr: Dr | undefined
  email: string | undefined
  givenName: string | undefined
  phone: string | undefined
  sn: string | undefined
  uid: string | undefined
  phoneOld?: string
  clearanceId?: number
  lastGdprAcceptance?: Dayjs | string
  firstGardianConnection?: boolean
  roleFunctions: RoleFunction[] = []
}

export const hasClearanceId = (user: User): boolean => {
  if (user?.clearanceId) {
    return true
  } else {
    return false
  }
}
