import { FC } from 'react'
import { useAuth } from 'src/hooks/use-auth'
import { FunctionsRdoEnum } from 'src/enums/functions-rdo.enum'

import { userRolesFunctions } from './can-user-access-utils'

interface AccessFunctionProps {
  functionRdo: FunctionsRdoEnum
  children: React.ReactNode
}

const AccessMenu: FC<AccessFunctionProps> = (props) => {
  const { functionRdo, children } = props
  const { user } = useAuth()

  // Vérification si la fonction est accéssible à tous ou si l'utilisateur a la fonction dans sa liste rolesFunction
  const canAccess =
    functionRdo === FunctionsRdoEnum.ALL_ACCESS ||
    !!userRolesFunctions({
      functionRdo,
      user
    })?.length
  return <>{canAccess && children}</>
}

export default AccessMenu
