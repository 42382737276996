import type { RouteObject } from 'react-router'
import Error401Page from 'src/pages/401'
import Error404Page from 'src/pages/404'
import Error500Page from 'src/pages/500'
import LogoutPage from 'src/pages/logout'
import { dashboardRoutes } from './dashboard'

export const routes: RouteObject[] = [
  ...dashboardRoutes,
  {
    path: '401',
    element: <Error401Page />
  },
  {
    path: '404',
    element: <Error404Page />
  },
  {
    path: '500',
    element: <Error500Page />
  },
  {
    path: 'logout',
    element: <LogoutPage />
  },
  {
    path: '*',
    element: <Error404Page />
  }
]
