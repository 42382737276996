import { FunctionsRdoEnum } from 'src/enums/functions-rdo.enum'
import { Dr } from 'src/types/dr'
import { User } from 'src/types/user'

interface UserOwnerAndDrRequested {
  userOwner?: string
  drRequested?: string
}

interface UserRolesFunctionsProps {
  functionRdo: FunctionsRdoEnum
  user: User | null
}
export const userRolesFunctions = (props: UserRolesFunctionsProps) => {
  const { functionRdo, user } = props
  // Liste des rolefonction de l'utilisateur pour la fonction RDO
  let roleFunctionsForProfils = user?.roleFunctions.filter(
    (roleFunction) => roleFunction.functionId === functionRdo
  )
  return roleFunctionsForProfils
}

interface FunctionAccessProps {
  functionRdo: FunctionsRdoEnum
  dr: Dr
  user: User | null
  userOwnerAndDrRequested?: UserOwnerAndDrRequested
}

const isFunctionEnabled = (props: FunctionAccessProps) => {
  const { functionRdo, dr, user, userOwnerAndDrRequested } = props

  const roleFunctionsForProfils = userRolesFunctions({ functionRdo, user })
  // Si l'utilisateur n'a pas de roleFunction c'est qu'il n'a pas accès à la fonctionaltié
  if (!roleFunctionsForProfils) {
    return false
  }

  // On recherche si il y a des condition particulière à l'accès de la fonctionalité
  const userCanAccess = roleFunctionsForProfils.some((roleFunctionForProfil) => {
    if (!roleFunctionForProfil.mustBeOwner && !roleFunctionForProfil.mustBeDr) {
      return true
    } else if (roleFunctionForProfil.mustBeOwner && roleFunctionForProfil.mustBeDr) {
      if (
        user?.uid === userOwnerAndDrRequested?.userOwner &&
        dr?.id === userOwnerAndDrRequested?.drRequested
      ) {
        return true
      }
    } else if (roleFunctionForProfil.mustBeOwner) {
      if (user?.uid === userOwnerAndDrRequested?.userOwner) {
        return true
      }
    } else if (roleFunctionForProfil.mustBeDr) {
      if (dr?.id === userOwnerAndDrRequested?.drRequested) {
        return true
      }
    }
    return false
  })
  return userCanAccess
}

export default isFunctionEnabled
