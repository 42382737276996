import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { Dr } from 'src/types/dr'

type ChangeDrEventAction = PayloadAction<Dr>

const initialState = {
  id: '',
  name: '',
  latitude: 48.856614,
  longitude: 2.3522219,
  zoom: 7,
  subExploitantId: 1,
  targetFlightTimePerPilotInMinutes: 120
} as Dr

const reducers = {
  changeCurrentDr: (state: Dr, action: ChangeDrEventAction) => {
    const payload = action.payload
    state.id = payload.id
    state.latitude = payload.latitude
    state.longitude = payload.longitude
    state.name = payload.name
    state.zoom = payload.zoom
    state.subExploitantId = payload.subExploitantId
    state.targetFlightTimePerPilotInMinutes = payload.targetFlightTimePerPilotInMinutes
  }
}
export const drSlice = createSlice({
  name: 'currentDr',
  initialState,
  reducers
})

export const { reducer } = drSlice
