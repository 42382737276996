import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'dayjs/locale/fr'
import L from 'leaflet'
/** Leaflet **/
import 'leaflet-draw/dist/leaflet.draw.css'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/leaflet.css'
/** Nprogress **/
import 'nprogress/nprogress.css'
import 'primeicons/primeicons.css'
/** PrimeReact **/
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
import type { FC } from 'react'
import { Helmet } from 'react-helmet-async'
// Remove if react-quill is not used
import 'react-quill/dist/quill.snow.css'
import { Provider as ReduxProvider } from 'react-redux'
import { useRoutes } from 'react-router-dom'
// Remove if simplebar is not used
import 'simplebar-react/dist/simplebar.min.css'
import { SplashScreen } from './components/splash-screen'
import { Toaster } from './components/toaster'
import { AuthConsumer, AuthProvider } from './contexts/auth/gardian-websso-context'
import { SettingsConsumer, SettingsProvider } from './contexts/settings-context'
import { useNprogress } from './hooks/use-nprogress'
import { routes } from './routes'
import { store } from './store'
import './stylesheets/application.css'
import './stylesheets/components/map-controls.css'
import { createTheme } from './theme'

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
})

L.Marker.prototype.options.icon = DefaultIcon

export const App: FC = () => {
  useNprogress()

  const element = useRoutes(routes)

  // Get the user's preferred theme from localStorage
  const initialTheme = localStorage.getItem('theme') || 'light'
  // Set the initial theme mode and render the app, use for adapt sheetstyles according selected theme
  document.documentElement.setAttribute('data-theme', initialTheme)

  return (
    <ReduxProvider store={store}>
      <LocalizationProvider
        adapterLocale="fr"
        dateAdapter={AdapterDayjs}>
        <AuthProvider>
          <AuthConsumer>
            {(auth) => (
              <SettingsProvider>
                <SettingsConsumer>
                  {(settings) => {
                    // Prevent theme flicker when restoring custom settings from browser storage
                    if (!settings.isInitialized) {
                      // return null;
                    }

                    const theme = createTheme({
                      colorPreset: settings.colorPreset,
                      contrast: settings.contrast,
                      direction: settings.direction,
                      paletteMode: settings.paletteMode,
                      responsiveFontSizes: settings.responsiveFontSizes
                    })

                    // Prevent guards from redirecting
                    const showSlashScreen = !auth.isInitialized
                    return (
                      <ThemeProvider theme={theme}>
                        <Helmet>
                          <meta
                            name="color-scheme"
                            content={settings.paletteMode}
                          />
                          <meta
                            name="theme-color"
                            content={theme.palette.neutral[900]}
                          />
                        </Helmet>
                        <CssBaseline />
                        {showSlashScreen ? <SplashScreen /> : <>{element}</>}
                        <Toaster />
                      </ThemeProvider>
                    )
                  }}
                </SettingsConsumer>
              </SettingsProvider>
            )}
          </AuthConsumer>
        </AuthProvider>
      </LocalizationProvider>
    </ReduxProvider>
  )
}
