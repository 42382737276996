import type { SVGProps } from 'react'

const Quadrocopter = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1">
      <g id="surface1">
        <path d="M 0.21875 8.9375 C -0.3125 9.5 0.65625 9.6875 3.9375 9.6875 C 6.90625 9.6875 7.5 9.59375 7.5 9.21875 C 7.5 8.84375 6.90625 8.75 3.96875 8.75 C 2 8.75 0.3125 8.84375 0.21875 8.9375 Z M 0.21875 8.9375 " />
        <path d="M 12.5 9.21875 C 12.5 9.625 13 9.6875 16.1875 9.625 C 20.96875 9.5 20.90625 8.9375 16.09375 8.8125 C 13 8.75 12.5 8.8125 12.5 9.21875 Z M 12.5 9.21875 " />
        <path d="M 2.40625 11.46875 C 1.96875 11.875 2.21875 13.125 2.78125 13.4375 C 3.125 13.59375 4.03125 13.75 4.8125 13.75 C 5.59375 13.75 6.25 13.78125 6.25 13.8125 C 6.25 13.875 5.96875 14.96875 5.625 16.25 C 4.90625 18.96875 4.84375 19.78125 5.375 19.625 C 5.59375 19.53125 6.0625 18.25 6.4375 16.75 C 6.8125 15.25 7.1875 13.96875 7.28125 13.875 C 7.34375 13.78125 7.65625 14.09375 7.9375 14.53125 C 8.375 15.1875 8.6875 15.3125 10 15.3125 C 11.3125 15.3125 11.625 15.1875 12.0625 14.53125 C 12.34375 14.09375 12.65625 13.78125 12.71875 13.875 C 12.8125 13.96875 13.1875 15.25 13.5625 16.75 C 13.9375 18.25 14.40625 19.53125 14.625 19.625 C 15.15625 19.78125 15.09375 18.96875 14.375 16.25 C 14.03125 14.96875 13.75 13.875 13.75 13.8125 C 13.75 13.78125 14.40625 13.75 15.1875 13.75 C 15.96875 13.75 16.875 13.59375 17.21875 13.4375 C 17.9375 13.03125 18 11.71875 17.3125 11.4375 C 16.65625 11.1875 2.65625 11.1875 2.40625 11.46875 Z M 2.40625 11.46875 " />
      </g>
    </svg>
  )
}

export default Quadrocopter
