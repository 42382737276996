export const paths = {
  index: '/',
  missions: {
    list: '/flights/missions',
    create: '/flights/missions/create',
    details: '/flights/missions/:missionId',
    exclusionArea: '/flights/missions/:missionId/exclusionArea',
    edit: '/flights/missions/:missionId/edit',
    copy: '/flights/missions/:missionId/edit/:copy'
  },
  logbooks: {
    list: '/flights/logbooks',
    create: '/flights/logbooks/create',
    createFromMission: '/flights/logbooks/create/mission/:missionId',
    details: 'flights/logbooks/:logbookId',
    edit: '/flights/logbooks/:logbookId/edit'
  },
  drones: {
    list: '/management/drones',
    details: '/management/drones/:idDrone'
  },
  folders: {
    list: '/folders',
    details: '/folders/:folderId',
    create: '/folders/create',
    edit: '/folders/:folderId/edit',
    addRepport: '/folders/:folderId/add-report',
    report: '/reports/:reportId',
    editReport: '/reports/:reportId/edit'
  },
  users: {
    list: '/management/users',
    details: '/management/users/:idUser',
    edit: '/management/users/:idUser/edit'
  },
  vps: {
    list: '/management/vps',
    details: '/management/vps/:vpsId',
    create: '/management/vps/create',
    edit: '/management/vps/:vpsId/edit'
  },
  rex: {
    list: '/management/rex',
    details: '/management/rex/:rexId',
    create: '/management/rex/create',
    edit: '/management/rex/:rexId/edit'
  },
  calendar: '/management/calendar',
  kanban: '/management/kanban',
  kpiDashboard: '/management/dashboard',
  account: '/account',
  documentation: '/documentation',
  logout: '/logout',
  401: '/401',
  404: '/404',
  500: '/500'
}
