import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { useSettings } from 'src/hooks/use-settings'
import { withAuthGuard } from 'src/hooks/with-auth-guard'
import { useSections } from './config'
import { HorizontalLayout } from './horizontal-layout'
import { VerticalLayout } from './vertical-layout'

interface LayoutProps {
  children?: ReactNode
}

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {
  const settings = useSettings()
  const sections = useSections()

  if (settings.layout === 'horizontal') {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props}
      />
    )
  }

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      {...props}
    />
  )
})

Layout.propTypes = {
  children: PropTypes.node as React.Validator<React.ReactNode>
}
