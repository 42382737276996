import { lazy, Suspense } from 'react'
import type { RouteObject } from 'react-router'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from 'src/layouts/dashboard'
import { paths } from 'src/paths'

const IndexPage = lazy(() => import('src/pages/personal-dashboard/index'))
// Other
const AccountPage = lazy(() => import('src/pages/personal-dashboard/account'))
const BlankPage = lazy(() => import('src/pages/personal-dashboard/blank'))
const FlightMissionListPage = lazy(() => import('src/pages/flight/missions'))
const DroneListPage = lazy(() => import('src/pages/management/drone/drones'))
const DroneDetailsPage = lazy(() => import('src/pages/management/drone/drone'))
const FlightMissionCreatePage = lazy(() => import('src/pages/flight/mission-create'))
const FlightMissionDetailsPage = lazy(() => import('src/pages/flight/mission'))
const FlightMissionExclusionAreaPage = lazy(() => import('src/pages/flight/mission-exclusion-area'))
const DocumentationPage = lazy(() => import('src/pages/documentation/documentation'))
const FolderListPage = lazy(() => import('src/pages/folders/folders'))
const FolderDetailsPage = lazy(() => import('src/pages/folders/folder'))
const FolderCreatePage = lazy(() => import('src/pages/folders/folder-create'))
const CalendarPage = lazy(() => import('src/pages/management/calendar'))
const VpsListPage = lazy(() => import('src/pages/management/vps/vps-list'))
const VpsDetailsPage = lazy(() => import('src/pages/management/vps/vps-details'))
const VpsCreatePage = lazy(() => import('src/pages/management/vps/vps-create'))
const UserListPage = lazy(() => import('src/pages/management/user/users'))
const UserDetailsPage = lazy(() => import('src/pages/management/user/user'))
const AddReportPage = lazy(() => import('src/pages/folders/report/add-report'))
const EditReportPage = lazy(() => import('src/pages/folders/report/edit-report'))
const ReportPage = lazy(() => import('src/pages/folders/report/report'))
const RexListPage = lazy(() => import('src/pages/management/rex/rex-list'))
const RexDetailsPage = lazy(() => import('src/pages/management/rex/rex-details'))
const RexCreatePage = lazy(() => import('src/pages/management/rex/rex-create'))
const LogbookListPage = lazy(() => import('src/pages/flight/logbook/logbooks'))
const LogbookDetailsPage = lazy(() => import('src/pages/flight/logbook/logbook'))
const LogbookCreatePage = lazy(() => import('src/pages/flight/logbook/logbook-create'))
const KanbanPage = lazy(() => import('src/pages/management/kanban'))
const KpiDashboard = lazy(() => import('src/pages/management/kpi-dashboard'))

export const dashboardRoutes: RouteObject[] = [
  {
    path: '',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />
      },
      {
        path: 'account',
        element: <AccountPage />
      },
      {
        path: 'blank',
        element: <BlankPage />
      },
      {
        path: paths.missions.list,
        element: <FlightMissionListPage />
      },

      {
        path: paths.missions.create,
        element: <FlightMissionCreatePage />
      },
      {
        path: paths.missions.edit,
        element: <FlightMissionCreatePage />
      },
      {
        path: paths.missions.copy,
        element: <FlightMissionCreatePage />
      },
      {
        path: paths.missions.details,
        element: <FlightMissionDetailsPage />
      },
      {
        path: paths.missions.exclusionArea,
        element: <FlightMissionExclusionAreaPage />
      },
      {
        path: paths.logbooks.list,
        element: <LogbookListPage />
      },
      {
        path: paths.logbooks.details,
        element: <LogbookDetailsPage />
      },
      {
        path: paths.logbooks.create,
        element: <LogbookCreatePage />
      },
      {
        path: paths.logbooks.createFromMission,
        element: <LogbookCreatePage />
      },
      {
        path: paths.logbooks.edit,
        element: <LogbookCreatePage />
      },
      {
        path: paths.drones.list,
        element: <DroneListPage />
      },
      {
        path: paths.drones.details,
        element: <DroneDetailsPage />
      },
      {
        path: paths.vps.list,
        element: <VpsListPage />
      },
      {
        path: paths.vps.create,
        element: <VpsCreatePage />
      },
      {
        path: paths.vps.edit,
        element: <VpsCreatePage />
      },
      {
        path: paths.vps.details,
        element: <VpsDetailsPage />
      },
      {
        path: paths.rex.list,
        element: <RexListPage />
      },
      {
        path: paths.rex.create,
        element: <RexCreatePage />
      },
      {
        path: paths.rex.edit,
        element: <RexCreatePage />
      },
      {
        path: paths.rex.details,
        element: <RexDetailsPage />
      },
      {
        path: paths.documentation,
        element: <DocumentationPage />
      },
      {
        path: paths.folders.list,
        element: <FolderListPage />
      },
      {
        path: paths.folders.details,
        element: <FolderDetailsPage />
      },
      {
        path: paths.folders.edit,
        element: <FolderCreatePage />
      },
      {
        path: paths.folders.create,
        element: <FolderCreatePage />
      },
      {
        path: paths.users.list,
        element: <UserListPage />
      },
      {
        path: paths.users.details,
        element: <UserDetailsPage />
      },
      {
        path: paths.calendar,
        element: <CalendarPage />
      },
      {
        path: paths.kpiDashboard,
        element: <KpiDashboard />
      },
      {
        path: paths.kanban,
        element: <KanbanPage />
      },
      {
        path: paths.folders.addRepport,
        element: <AddReportPage />
      },
      {
        path: paths.folders.editReport,
        element: <EditReportPage />
      },
      {
        path: paths.folders.report,
        element: <ReportPage />
      }
    ]
  }
]
