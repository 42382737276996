import type { PaletteColor } from '@mui/material'
import type { EnedisColors, NeutralColors, NeutralEnedisColors } from '@mui/material/styles'
import { alpha } from '@mui/material/styles'

const withAlphas = (color: PaletteColor): PaletteColor => {
  return {
    ...color,
    alpha4: alpha(color.main, 0.04),
    alpha8: alpha(color.main, 0.08),
    alpha12: alpha(color.main, 0.12),
    alpha30: alpha(color.main, 0.3),
    alpha50: alpha(color.main, 0.5)
  }
}

export const neutral: NeutralColors = {
  50: '#F8F9FA',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D2D6DB',
  400: '#9DA4AE',
  500: '#6C737F',
  600: '#4D5761',
  700: '#2F3746',
  800: '#1C2536',
  900: '#111927'
}

// Old success, info, warning and error colors previously used (original colors from Devias template)
export const successDevias = withAlphas({
  lightest: '#F0FDF9',
  light: '#3FC79A',
  main: '#10B981',
  dark: '#0B815A',
  darkest: '#134E48',
  contrastText: '#FFFFFF'
})

export const infoDevias = withAlphas({
  lightest: '#ECFDFF',
  light: '#CFF9FE',
  main: '#06AED4',
  dark: '#0E7090',
  darkest: '#164C63',
  contrastText: '#FFFFFF'
})

export const warningDevias = withAlphas({
  lightest: '#FFFAEB',
  light: '#FEF0C7',
  main: '#F79009',
  dark: '#B54708',
  darkest: '#7A2E0E',
  contrastText: '#FFFFFF'
})

export const errorDevias = withAlphas({
  lightest2: '#FFE0E6',
  lightest: '#FFA3B4',
  light: '#FF4768',
  main: '#E10028',
  dark: '#AD001F',
  darkest: '#7A0016',
  contrastText: '#FFFFFF'
})

export const blue = withAlphas({
  lightest: '#F5F8FF',
  light: '#EBEFFF',
  main: '#2970FF',
  dark: '#004EEB',
  darkest: '#00359E',
  contrastText: '#FFFFFF'
})

// Currently used by the theme -> adapt this with the rework of the design of the app
export const green = withAlphas({
  lightest: '#F6FEF9',
  light: '#EDFCF2',
  // Vert Enedis
  main: '#96CD32',
  // Vert foncé charte Enedis
  dark: '#41a57D',
  darkest: '#084C2E',
  contrastText: '#FFFFFF'
})

export const indigo = withAlphas({
  lightest: '#F5F7FF',
  light: '#EBEEFE',
  main: '#6366F1',
  dark: '#4338CA',
  darkest: '#312E81',
  contrastText: '#FFFFFF'
})

export const purple = withAlphas({
  lightest: '#F9F5FF',
  light: '#F4EBFF',
  main: '#9E77ED',
  dark: '#6941C6',
  darkest: '#42307D',
  contrastText: '#FFFFFF'
})

// Success, info, warning and error colors used by the theme (adapt with Enendis colors)
export const success = withAlphas({
  lightest2: '#E9F6F1',
  lightest: '#A0D9C2',
  light: '#62C09A',
  main: '#41A57D',
  dark: '#368766',
  darkest: '#1D4938',
  contrastText: '#FFFFFF'
})

export const info = withAlphas({
  lightest2: '#E5F1FB',
  lightest: '#B1D4F2',
  light: '#6FB1E7',
  main: '#2382D2',
  dark: '#1F73B7',
  darkest: '#18578C',
  contrastText: '#FFFFFF'
})

export const warning = withAlphas({
  lightest2: '#FFF6E0',
  lightest: '#FFE3A3',
  light: '#FFCE5C',
  main: '#FFC328',
  dark: '#F5AB00',
  darkest: '#943510',
  contrastText: '#FFFFFF'
})

export const error = withAlphas({
  lightest2: '#FFE0E6',
  lightest: '#FFA3B4',
  light: '#FF4768',
  main: '#E10028',
  dark: '#AD001F',
  darkest: '#7A0016',
  contrastText: '#FFFFFF'
})

// Couleurs Enedis
export const neutralEnedis: NeutralEnedisColors = {
  50: '#FFFFFF',
  75: '#FAFAFA',
  100: '#F1F2F3',
  200: '#E9EBEC',
  300: '#DEE0E3',
  400: '#C8CBD0',
  500: '#AEB1B7',
  600: '#868A93',
  700: '#60616C',
  800: '#3B3C45',
  900: '#101014'
}

export const bleuEnedis: EnedisColors = {
  200: '#F6F6FE',
  300: '#DADDFC',
  400: '#4D59EF',
  500: '#1423DC',
  600: '#0F1AA4',
  700: '#070D4F'
}

export const vertEnedis: EnedisColors = {
  200: '#F7FBEF',
  300: '#D5EBAD',
  400: '#B5DC6F',
  500: '#96CD32',
  600: '#698F23',
  700: '#3C5214'
}

export const bleuMoyenEnedis: EnedisColors = {
  200: '#E5F1FB',
  300: '#B1D4F2',
  400: '#6FB1E7',
  500: '#2382D2',
  600: '#1F73B7',
  700: '#18578C'
}

export const turquoiseEnedis: EnedisColors = {
  200: '#E8F7F7',
  300: '#BEE9E9',
  400: '#85D6D6',
  500: '#4BC3C3',
  600: '#37A4A4',
  700: '#2A7E7E'
}

export const vertFonceEnedis: EnedisColors = {
  200: '#E9F6F1',
  300: '#A0D9C2',
  400: '#62C09A',
  500: '#41A57D',
  600: '#368766',
  700: '#1D4938'
}

export const jauneSolaireEnedis: EnedisColors = {
  200: '#FFF6E0',
  300: '#FFE3A3',
  400: '#FFCE5C',
  500: '#FFC328',
  600: '#F5AB00',
  700: '#805900'
}

export const rougeEnedis: EnedisColors = {
  200: '#FFE0E6',
  300: '#FFA3B4',
  400: '#FF4768',
  500: '#E10028',
  600: '#AD001F',
  700: '#7A0016'
}

export const orangeEnedis: EnedisColors = {
  200: '#FCEAE3',
  300: '#F6C1AC',
  400: '#F19F7E',
  500: '#EB6E3C',
  600: '#E85921',
  700: '#943510'
}

export const pruneEnedis: EnedisColors = {
  200: '#F9E6F5',
  300: '#EEB4E1',
  400: '#D7ACBB',
  500: '#AF2891',
  600: '#851E6E',
  700: '#5C154C'
}

export const violetEnedis: EnedisColors = {
  200: '#F9E6F5',
  300: '#EEB4E1',
  400: '#D7ACBB',
  500: '#AF2891',
  600: '#851E6E',
  700: '#43236C'
}

export const bleuFonceEnedis: EnedisColors = {
  200: '#E8E9F8',
  300: '#B9BCE9',
  400: '#3841B7',
  500: '#232873',
  600: '#1A1E56',
  700: '#0E112F'
}

export const taupeEnedis: EnedisColors = {
  200: '#F5F1EA',
  300: '#ECE3D5',
  400: '#DAC9AF',
  500: '#C8AF87',
  600: '#B79662',
  700: '#695330'
}

export const marronEnedis: EnedisColors = {
  200: '#F1EFEF',
  300: '#DCD6D6',
  400: '#B1A4A4',
  500: '#7C6A6A',
  600: '#463C3C',
  700: '#292323'
}

export const vertEnedis500Theming = (theme: any) =>
  theme.palette.mode === 'dark' ? vertFonceEnedis[500] : vertEnedis[500]

export const vertEnedisBackgroundTheming = (theme: any) =>
  theme.palette.mode === 'dark' ? '#112c32' : '#f2f9e6'

export const bleuEnedis500Theming = (theme: any) =>
  theme.palette.mode === 'dark' ? bleuMoyenEnedis[500] : bleuEnedis[500]
