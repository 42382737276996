import type { FC } from 'react'

export const Logo: FC = () => {
  return (
    <img
      alt="Logo"
      width="42px"
      height="42px"
      src="/assets/logos/Enedis_Icone_couleur_RVB_300 dpi.png"
    />
  )
}
