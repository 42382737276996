import React, { FC, MouseEvent, useContext, ReactNode, cloneElement } from 'react'
import { DropdownContext } from './dropdown-context'
import PropTypes from 'prop-types'

interface DropdownButtonProps {
  children: ReactNode
}

export const DropdownTrigger: FC<DropdownButtonProps> = (props) => {
  const { children } = props
  const { onTriggerEnter, onTriggerLeave } = useContext(DropdownContext)

  return cloneElement(children as React.ReactElement, {
    onMouseEnter: (event: MouseEvent<HTMLElement>) => {
      ;(children as React.ReactElement).props.onMouseEnter?.(event)
      onTriggerEnter(event)
    },
    onMouseLeave: (event: MouseEvent<HTMLElement>) => {
      ;(children as React.ReactElement).props.onMouseLeave?.(event)
      onTriggerLeave(event)
    }
  })
}

// TypeScript prop type validation (not PropTypes)
DropdownTrigger.propTypes = {
  children: PropTypes.node.isRequired as React.Validator<React.ReactNode>
}
