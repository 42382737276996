export enum FunctionsRdoEnum {
  ALL_ACCESS = 'all:access',
  REPORTS_READ = 'reports:read',
  REPORTS_EDIT = 'reports:edit',
  REPORTS_DELETE = 'reports:delete',
  FOLDERS = 'folders',
  FOLDERS_READ = 'folders:read',
  FOLDERS_CREATE = 'folders:create',
  FOLDERS_REPORT_CREATE = 'folders:report:create',
  FOLDERS_EDIT = 'folders:edit',
  FOLDERS_DELETE = 'folders:delete',
  MISSIONS = 'missions',
  MISSIONS_READ = 'missions:read',
  MISSIONS_DUPLICATE = 'missions:duplicate',
  MISSIONS_EDIT = 'missions:edit',
  MISSIONS_ARCHIVE = 'missions:archive',
  MISSIONS_DELETE = 'missions:delete',
  MISSIONS_CREATE = 'missions:create',
  MISSIONS_PILOTS = 'missions:pilots',
  MISSIONS_EXCLUSION_ZONE = 'missions:exclusion_zone',
  LOGBOOKS = 'logbooks',
  LOGBOOKS_READ = 'logbooks:read',
  LOGBOOKS_EDIT = 'logbooks:edit',
  LOGBOOKS_DELETE = 'logbooks:delete',
  LOGBOOKS_CREATE = 'logbooks:create',
  LOGBOOKS_EXPORT = 'logbooks:export',
  DASHBOARD = 'dashboard',
  DRONES = 'drones',
  DRONES_SYNC_CLEARANCE = 'drones:sync_clearance',
  VPS = 'vps',
  VPS_READ = 'vps:read',
  VPS_EDIT = 'vps:edit',
  VPS_DELETE = 'vps:delete',
  VPS_CREATE = 'vps:create',
  REX = 'rex',
  REX_READ = 'rex:read',
  REX_EDIT = 'rex:edit',
  REX_DELETE = 'rex:delete',
  REX_CREATE = 'rex:create',
  USERS = 'users',
  USERS_EXPORT = 'users:export',
  USERS_LOG_DOWNLOAD = 'users:log_download',
  USERS_EDIT = 'users:edit',
  USERS_SYNC_CLEARANCE = 'users:sync_clearance',
  KPI_DASHBOARD_READ = 'kpis:read',
  KPI_DASHBOARD = 'kpis',
  DR = 'dr'
}
