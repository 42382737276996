import { SvgIcon } from '@mui/material'
import BookIcon from '@untitled-ui/icons-react/build/esm/BookClosed'
import Calendar from '@untitled-ui/icons-react/build/esm/Calendar'
import ClipboardCheck from '@untitled-ui/icons-react/build/esm/ClipboardCheck'
import File5Icon from '@untitled-ui/icons-react/build/esm/File05'
import FolderIcon from '@untitled-ui/icons-react/build/esm/Folder'
import HomeSmileIcon from '@untitled-ui/icons-react/build/esm/HomeSmile'
import PieChart01 from '@untitled-ui/icons-react/build/esm/PieChart01'
import UsersIcon from '@untitled-ui/icons-react/build/esm/Users01'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { FunctionsRdoEnum } from 'src/enums/functions-rdo.enum'
import Quadrocopter from 'src/icons/quadrocopter'
import { paths } from 'src/paths'
import { useAuth } from 'src/hooks/use-auth'

export interface Item {
  disabled?: boolean
  external?: boolean
  icon?: ReactNode
  items?: Item[]
  label?: ReactNode
  path?: string
  title: string
  order?: number
  functionRdo: FunctionsRdoEnum
}

export interface Section {
  items: Item[]
  subheader?: string
  order: number
}

export const useSections = () => {
  const { user } = useAuth()

  return useMemo(() => {
    const getFlightItems = (): Item[] => {
      const flightMenuItems: Item[] = []
      flightMenuItems.push({
        title: 'Missions de vols',
        order: 1,
        path: paths.missions.list,
        icon: (
          <SvgIcon>
            <Quadrocopter />
          </SvgIcon>
        ),
        functionRdo: FunctionsRdoEnum.MISSIONS
      })
      flightMenuItems.push({
        title: 'Carnet de bord',
        order: 2,
        path: paths.logbooks.list,
        icon: (
          <SvgIcon fontSize="small">
            <BookIcon />
          </SvgIcon>
        ),
        functionRdo: FunctionsRdoEnum.LOGBOOKS
      })
      flightMenuItems.push({
        title: 'Liste des dossiers',
        path: paths.folders.list,
        icon: (
          <SvgIcon fontSize="small">
            <FolderIcon />
          </SvgIcon>
        ),
        functionRdo: FunctionsRdoEnum.FOLDERS
      })
      return flightMenuItems
    }
    const getManagementItems = (): Item[] => {
      const managementMenuItems: Item[] = []
      managementMenuItems.push({
        title: 'Calendrier',
        order: 1,
        path: paths.calendar,
        icon: (
          <SvgIcon fontSize="small">
            <Calendar />
          </SvgIcon>
        ),
        functionRdo: FunctionsRdoEnum.MISSIONS
      })
      managementMenuItems.push({
        title: 'Drones',
        order: 2,
        path: paths.drones.list,
        icon: (
          <SvgIcon>
            <Quadrocopter />
          </SvgIcon>
        ),
        functionRdo: FunctionsRdoEnum.DRONES
      })
      managementMenuItems.push({
        title: 'VPS',
        order: 3,
        path: paths.vps.list,
        icon: (
          <SvgIcon fontSize="small">
            <File5Icon />
          </SvgIcon>
        ),
        functionRdo: FunctionsRdoEnum.VPS
      })
      managementMenuItems.push({
        title: 'REX',
        order: 3,
        path: paths.rex.list,
        icon: (
          <SvgIcon fontSize="small">
            <File5Icon />
          </SvgIcon>
        ),
        functionRdo: FunctionsRdoEnum.REX
      })

      managementMenuItems.push({
        title: 'Gestions des utilisateurs',
        order: 4,
        path: paths.users.list,
        icon: (
          <SvgIcon fontSize="small">
            <UsersIcon />
          </SvgIcon>
        ),
        functionRdo: FunctionsRdoEnum.USERS
      })
      managementMenuItems.push({
        title: 'Tableau de bord',
        order: 5,
        path: paths.kpiDashboard,
        icon: (
          <SvgIcon fontSize="small">
            <PieChart01 />
          </SvgIcon>
        ),
        functionRdo: FunctionsRdoEnum.KPI_DASHBOARD
      })

      managementMenuItems.push({
        title: 'Fonctionnalités',
        order: 6,
        path: paths.kanban,
        icon: (
          <SvgIcon fontSize="small">
            <ClipboardCheck />
          </SvgIcon>
        ),
        functionRdo: FunctionsRdoEnum.ALL_ACCESS
      })
      return managementMenuItems
    }
    const sections: Section[] = []
    sections.push({
      items: [
        {
          title: 'Accueil',
          path: paths.index,
          icon: (
            <SvgIcon fontSize="small">
              <HomeSmileIcon />
            </SvgIcon>
          ),
          functionRdo: FunctionsRdoEnum.ALL_ACCESS
        }
      ],
      order: 1
    })
    sections.push({
      subheader: 'Vols',
      items: getFlightItems(),
      order: 2
    })
    sections.push({
      subheader: 'Management',
      items: getManagementItems(),
      order: 3
    })
    // Filter out sections based on user roles
    const filteredSections = sections
      .filter((section) => {
        if (section.subheader === 'Management' && user?.isSimpleUserAndNotAdvancedUser) {
          return false // Hide the 'Management' section
        }
        return true // Show the section by default
      })
      .sort((sectionA, sectionB) => sectionA.order - sectionB.order)
    return filteredSections
  }, [user])
}
