import type { Theme } from '@mui/material'
import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material'
import { RouterLink } from 'src/components/router-link'
import { Seo } from 'src/components/seo'
import { usePageView } from 'src/hooks/use-page-view'
import { paths } from 'src/paths'
import type { Page as PageType } from 'src/types/page'

const Page: PageType = () => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  usePageView()

  return (
    <>
      <Seo title="Erreur 404 : Page non trouvée" />
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          py: '80px'
        }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 6
            }}>
            <Box
              alt="Page non trouvée"
              component="img"
              src="/assets/errors/error-404.png"
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400
              }}
            />
          </Box>
          <Typography
            align="center"
            variant={mdUp ? 'h1' : 'h4'}>
            Erreur 404 : Page non trouvée
          </Typography>
          <Typography
            align="center"
            color="text.secondary"
            sx={{ mt: 0.5 }}>
            La page que vous souhaitez consulter est introuvable.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}>
            <Button
              component={RouterLink}
              href={paths.index}>
              Retour à l'accueil
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default Page
