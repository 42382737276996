import { drSlice } from 'src/slices/dr'
import type { AppThunk } from 'src/store'
import { Dr } from 'src/types/dr'

const changeCurrentDr =
  (dr: Dr): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(drSlice.actions.changeCurrentDr(dr))
  }

export const thunks = {
  changeCurrentDr
}
